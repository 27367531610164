.container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.form_container {
  width: 500px;
  border: 1px solid rgb(168, 168, 168);
  border-radius: 20px;
  padding: 50px;
}
