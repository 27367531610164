.loading {
  position: absolute;
}

.loading_child {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  background-color: #ffffff;
  background-color: rgba(178, 175, 175, 0.5);
}
