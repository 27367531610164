.container {
  margin: 100px 80px;
}

.table_nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: crimson;
}
